import * as _ from "lodash";

import DynamicSectionElement from "@/scripts/dynamic-section-element.js";

class Cart extends DynamicSectionElement {
  constructor() {
    super();
    this.time = new Date();
    this.isCartPage =
      // Bind update method so it can be passed as callback argument
      this.onCartUpdate = this.onCartUpdate.bind(this);
    this.mobile_notification = this.nextElementSibling.nextElementSibling;

    this.handleQuantityChange = _.debounce(
      () => {
        this.updateFromForm(this.querySelector("form"));
      },
      500,
      { leading: false, trailing: true }
    );

    // Use debounced event handler to update cart quanties
    this.addEventListener("change-quantity", () => {
      this.setAttribute("data-loading", true);
      this.querySelectorAll("quantity-input input").forEach((element) => {
        element.removeAttribute("disabled");
      });
      this.handleQuantityChange();
    });
  }

  openDrawer(itemId) {
    /*if (event && event.preventDefault) {
      if (event.metaKey) {
        return;
      }
      event.preventDefault();
    }*/
    if (this.getAttribute("type").indexOf("cart") == -1) {
      this.items = this.querySelectorAll("cart-item");
      let flag = true;
      this.items.forEach((element) => {
        if (
          (element.getAttribute("data-key").indexOf(itemId) >= 0 ||
            element.getAttribute("data-component-keys").indexOf(itemId) >= 0) &&
          flag
        ) {
          element.classList.replace("hidden", "flex");
          if (element.getAttribute("data-is-customily")) {
            let img_url = element.querySelector("img").src;
            this.checkImgReady(img_url, element);
          }
          flag = false;
        } else {
          element.classList.replace("flex", "hidden");
        }
      });
      this.classList.add("open");
      if (window.scrollY > 5) {
        this.style.top = "120px";
      }
      document.body.classList.add("overflow-hidden");
    }
  }

  checkImgReady(img_url, element) {
    let cart = this;
    element.querySelector("img").setAttribute("alt", "Loading...");
    fetch(img_url).then(function (response) {
      if (!response.ok) {
        // make the promise be rejected if we didn't get a 2xx response
        console.log("Customily is behind");
        setTimeout(() => {
          cart.checkImgReady(img_url, element);
        }, 200);
      } else {
        // got the desired response
        element.querySelector("img").setAttribute("alt", "Preview Image");
        element.querySelector("img").src =
          img_url + "?v=" + cart.time.getTime();
      }
    });
  }

  closeDrawer(e) {
    e.preventDefault();
    this.classList.remove("open");
    document.body.classList.remove("overflow-hidden");
    this.style.top = null;
  }

  // Add item to cart using HTML form data
  addItemFromForm(form) {
    const formData = new FormData(form);
    let itemId;
    for (const [key, value] of formData) {
      if (key == "id") {
        itemId = value;
      }
    }
    formData.append("sections", "site-header,cart");
    formData.append("sections_url", window.location.pathname);

    this.setAttribute("data-loading", true);

    console.debug("[CART] adding item from form", formData);

    fetch("/cart/add.js", {
      method: "POST",
      body: formData,
    }).then((response) => this.onCartUpdate(response, itemId));
  }

  // Update items in cart using HTML form data
  updateFromForm(form) {
    const formData = new FormData(form);
    formData.append("sections", "site-header,cart");
    formData.append("sections_url", window.location.pathname);

    this.setAttribute("data-loading", true);
    fetch("/cart/update.js", {
      method: "POST",
      body: formData,
    }).then(this.onCartUpdate);
  }

  updateCart(itemId) {
    const cart = this;
    function handleResponse() {
      let json = JSON.parse(this.responseText);
      const newCartEl = new DOMParser().parseFromString(json.cart, "text/html");
      cart.replaceContent(newCartEl);
      if (itemId) {
        cart.openDrawer(itemId);
      }
    }
    const request = new XMLHttpRequest();
    request.addEventListener("load", handleResponse);
    request.open("GET", "/?sections=cart", true);
    request.send();
  }

  // Remove an item from cart using line item key
  removeItem(key) {
    this.setAttribute("data-loading", true);

    fetch("/cart/update.js", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        updates: { [key]: 0 },
        sections: "site-header,cart",
        sections_url: window.location.pathname,
      }),
    }).then(this.onCartUpdate);
  }

  // Remove an customily item and addons from cart
  removeCustomilyItem(key) {
    this.setAttribute("data-loading", true);
    let mainItem;

    fetch("/cart.json")
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        const mainId = key;
        console.log("[Customily Cart] Found mainId", { mainId: mainId });

        if (response.items.length === 0) {
          console.log("[Customily Cart] No cart items");
          return;
        }

        mainItem = response.items.find(function (item) {
          return item.properties["_customily-upsell-main-id"] == mainId;
        });

        const item = response.items.find(function (item) {
          return item.properties["_customily-upsell-id"] == mainId;
        });

        if (!item) {
          console.log("[Customily Cart] Could not find item with id", {
            mainId: mainId,
            response: response,
          });
          return;
        }

        const customilyMainUid = item.properties["_customily-upsell-id"];

        if (!customilyMainUid) {
          console.log("[Customily Cart] Could not uuid for item with id", {
            mainId: mainId,
          });
          return;
        }

        let ids = response.items.filter(function (item) {
          return item.properties["_customily-upsell-id"] === customilyMainUid;
        });

        if (ids.length === 0) {
          console.log("[Customily Cart] no matching children for item", {
            mainId: mainId,
            customilyMainUid: customilyMainUid,
          });

          return;
        }

        ids = ids.map(function (item) {
          return item.key;
        });
        console.log("[Customily Cart] Found child ids", { ids: ids });

        let data = {};

        ids.forEach(function (id) {
          data[id] = 0;
        });

        console.log("[Customily Cart] Updating cart", { payload: data });

        return fetch("/cart/update.js", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            updates: data,
          }),
        });
      })
      .then(function (response) {
        console.log("[Customily Cart] response", { response: response });
        const mainId = mainItem.key;

        console.log("[Customily Cart] Updating cart, removing parent");

        const data = {};
        data[mainId] = 0;

        return fetch("/cart/update.js", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            updates: data,
            sections: "site-header,cart",
            sections_url: window.location.pathname,
          }),
        });
      })
      .then(this.onCartUpdate);

    /*fetch("/cart/update.js", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        updates: { [key]: 0 },
        sections: "site-header,cart",
        sections_url: window.location.pathname,
      }),
    }).then(this.onCartUpdate);*/
  }

  // Remove an cyp item and addons from cart
  removeCYPItem() {
    this.setAttribute("data-loading", true);
    let data = {};

    document.querySelectorAll("[data-is-cyp]").forEach((el) => {
      data[el.getAttribute("data-key")] = 0;
    });

    return fetch("/cart/update.js", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        updates: data,
        sections: "site-header,cart",
        sections_url: window.location.pathname,
      }),
    }).then(this.onCartUpdate);
  }

  removeDiamonds(key) {
    this.setAttribute("data-loading", true);
    let data = {};

    data[key] = 0;

    document.querySelectorAll("[data-is-diamonds]").forEach((el) => {
      data[el.getAttribute("data-key")] = 0;
    });

    return fetch("/cart/update.js", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        updates: data,
        sections: "site-header,cart",
        sections_url: window.location.pathname,
      }),
    }).then(this.onCartUpdate);
  }

  // Handle cart API response and update sections with dynamic content
  onCartUpdate(cartResponse, itemId) {
    cartResponse.json().then(({ sections }) => {
      // Replace content for re-rendered cart section
      const newCartEl = new DOMParser().parseFromString(
        sections.cart,
        "text/html"
      );
      this.replaceContent(newCartEl);

      // Replace content for re-rendered site-header section
      const newSiteHeaderEl = new DOMParser().parseFromString(
        sections["site-header"],
        "text/html"
      );
      document.querySelector("site-header").replaceContent(newSiteHeaderEl);

      this.setAttribute("data-loading", false);
      if (window.matchMedia("(min-width: 768px)").matches) {
        this.openDrawer(itemId);
      } else {
        this.mobile_notification.classList.remove("-translate-y-hidden-cart");
        this.mobile_notification.classList.add("p-5");
        setTimeout(() => {
          this.mobile_notification.classList.add("-translate-y-hidden-cart");
          this.mobile_notification.classList.remove("p-5");
        }, 3000);
      }
    });
  }
}

customElements.get("shopify-cart") ||
  customElements.define("shopify-cart", Cart);
